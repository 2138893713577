const es = {
    common: {
        save: "Guardar",
        delete: "Eliminar",
        update_preview: "Actualizar previsualización",
        edit: "Editar",
        ok: "Aceptar",
        cancel: "Cancelar",
        name: "Nombre",
        date: "Fecha",
        start: "Inicio",
        end: "Fin",
        no_privileges: "Usuario sin privilegios para esta funcionalidad",
        time: "Hora",
        session_expired: "Sesión finalizada",
        cameras: "Cámaras",
        configuration: "Configuración",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        groups: "Grupos",
        information: "Información",
        yes: "Sí",
        no: "No",
        copyright: "Copyright © {year}",
        all_rights_reserved: "Todos los derechos reservados",
        recordings: "Grabaciones",
        tasks: "Programaciones",
        labels: "Etiquetas",
        exports: "Exportaciones",
        notifications: "Notificaciones",
        search: "Buscar",
        filters: "Filtros",
        users: "Usuarios",
    },
    weekdays: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
        all: "Todos",



    },


    cameras: {
        new_camera: "Nueva cámara",
        information: "Información",
        connection: "Conexión",
        camera_name: "Nombre de la cámara",
        mac: "Dirección MAC",
        camera_mac: "MAC de la cámara",
        vcode: "VCode",
        camera_vcode: "VCode de la cámara",
        user: "Usuario",
        camera_user: "Usuario de la cámara",
        password: "Contraseña",
        camera_password: "Contraseña de la cámara",
        profile: "Perfil",
        name_required: "Nombre requerido",
        mac_required: "MAC requerida",
        vcode_required: "VCode requerido",
        user_required: "Usuario requerido",
        password_required: "Contraseña requerida",
        review_connection_params: "Revise los parámetros de conexión",
        motion: "Movimiento",
        video: "Vídeo",
        audio: "Audio",
        channel: "Canal",
        brand: "Marca",
        camera_brand: "Fabricante de la cámara",
        model: "Modelo",
        camera_model: "Modelo de la cámara",
        ip_dn: "IP/DN",
        camera_ip_dn: "IP/DN de la cámara",
        camera_profile: "Perfil de la cámara",
        detection: "Detección",
        sensitivity: "Sensibilidad",
        low: "Baja",
        medium: "Media",
        high: "Alta",
        region: "Región",
        full: "Completa",
        custom: "Personalizada",
        enable: "Habilitar",
        error_updating_camera: "Error al actualizar la cámara",
        edit_camera: "Editar Cámara",
        setup_wifi: "Configurar Wi-Fi",
        updating_preview: "Actualizando previsualización",
        no_preview_available: "No hay previsualización disponible",
        select_update_preview: "Seleccione Actualizar previsualización",
        error_updating_preview: "Error actualizando previsualización",
        no_cameras: "No hay cámaras dadas de alta en el sistema",
        no_cameras_with_label: "No hay cámaras con la etiqueta seleccionada",
        cam_del_confirmation: "¿Desea eliminar la cámara '{camname}'?",
        no_licenses_for_new_camera: "No dispone de licencias para añadir una nueva cámara",
        cameras: "Cámaras",
        security: "Seguridad",
        searching_wifi: "Buscando redes Wi-Fi...",
        configuring_the_camera_wait: "Configurando la cámara. Espere y no abandone la página, por favor...",
        error_getting_wifi: "Error al obtener la configuración Wi-Fi de la cámara",
        error_getting_network_list: "Error al obtener la lista de redes disponibles",
        new_password_sent: "Enviada nueva contraseña",
        please_wait: "Espere, por favor...",
        configuring_wifi: "Configurando la red Wi-Fi de la cámara",
        camera_configured_successfully: "Cámara configurada correctamente",
        error_configuring_wifi: "Error al configurar la red Wi-Fi",
        wifi_config: "Configuración Wi-Fi",
        wifi_password: "Contraseña Wi-Fi",
        enter_password: "Introduzca una contraseña",
        passwords_dont_match: "Las contraseñas no coinciden",
        repeat_password: "Repita contraseña",





    },
    exports: {
        export_name: "Nombre de la exportación",
        export: "Exportar",
        export_name_required: "Nombre de la exportación requerido",
        invalid_start_time: "Hora de inicio no válida",
        invalid_end_time: "Hora de fin no válida",
        review_export_params: "Revise los parámetros de exportación",
        download: "Descargar",
        no_exports: "No hay exportaciones en el sistema",
        export_del_confirmation: "¿Desea eliminar la exportación de la cámara '{camname}'?",

    },

    groups: {
        interval: "Intervalo",
        group_name: "Nombre del grupo",
        geometry: "Geometría",
        name_required: "Nombre del grupo requerido",
        at_least_one_camera: "Debe seleccionar al menos una cámara",
        error_saving_group: "Error al guardar el grupo",
        error_updating_group: "Error al actualizar el grupo",
        new_group: "Nuevo grupo",
        edit_group: "Editar grupo",
        updating_preview: "Actualizando previsualización",
        no_preview_available: "No hay previsualización disponible",
        select_update_preview: "Seleccione Actualizar previsualización",
        error_updating_preview: "Error actualizando previsualización",
        no_groups: "No hay grupos dados de alta en el sistema",
        no_groups_with_label: "No hay grupos con la etiqueta seleccionada",
        group_del_confirmation: "¿Desea eliminar el grupo '{groupname}'?",


    },

    labels: {
        label_name: "Nombre de la etiqueta",
        name_required: "Nombre de etiqueta requerido",
        error_saving_label: "Error al guardar la etiqueta",
        error_updating_label: "Error al actualizar la etiqueta",
        no_labels: "No hay etiquetas definidas",
        alerts_enabled: "Alertas activadas",
        label_del_confirmation: "¿Desea eliminar la etiqueta '{labelname}'?",
        
    },

    login: {
        email: "E-mail",
        new_password: "Nueva contraseña",
        invalid_email: "E-mail no válido",
        password_too_short: "La contraseña debe tener al menos 8 caracteres",
        password_updated: "Contraseña actualizada",
        error_updating_password: "Error al actualizar contraseña",
        send: "ENVIAR",
        review_inbox: "Revise su bandeja de entrada",
        error_sending_email: "Error al enviar el e-mail",
        password: "Contraseña",
        invalid_credentials: "Credenciales no válidas",
        forgot_password: "¿Ha olvidado su contraseña?",
        enter: "ENTRAR",
        entering_our_web: "Al entrar a nuestra web acepta las",
        terms_of_service: "condiciones del servicio",
        welcome: "Bienvenido",


    },

    menus: {
        close_session: "Cerrar sesión",
        my_account: "Mi cuenta",
        users: "Usuarios",
        change_password: "Cambiar contraseña",
        settings: "Configuración",
        user_manual: "Manual de usuario",
        all: "Todas",


    }, 

    notifications: {
        no_notifications: "No hay notificaciones",
        type: "Tipo",
        info: "Info",
        mark_all_read: "Marcar todas como leídas",
        delete_all: "Eliminar todas",
        notifications_enabled: "Notificaciones habilitadas",
        notifications_disabled: "Notificaciones deshabilitadas",
        connection_lost: "¡Conexión perdida!",
        connection_recovered: "¡Conexión recuperada!",
        motion_detected: "¡Movimiento detectado!",
        audio_detected: "¡Audio detectado!",
        live_popup: "¡Evento recibido!",
        unknown: "Desconocida",

    },

    recordings: {
        export: "Exportar",
        lock: "Proteger",
        unlock: "Desproteger",
        type: "Tipo",
        camera: "Cámara",
        select: "Seleccionar",
        no_recordings: "No hay grabaciones en el sistema",
        rec_del_confirmation: "¿Desea eliminar la grabación de la cámara '{camname}'?",
        no_recs_for_date: "No hay grabaciones para la fecha de busqueda"
    },

    tasks: {
        timetable: "Horarios",
        task_name: "Nombre de la programación",
        type: "Tipo",
        continuous: "Grabación continua",
        motion_detection: "Detección de movimiento",
        motion_notification: "Notificación de movimiento",
        audio_notification: "Notificación de audio",
        day: "Día",
        add: "Añadir",
        unassigned: "Sin asignar",
        all: "Todas",
        name_required: "Nombre de la programación requerido",
        at_least_one_timetable: "Debe seleccionar al menos un horario",
        at_least_one_camera: "Debe seleccionar al menos una cámara",
        error_saving_task: "Error al guardar la programación",
        error_updating_task: "Error al actualizar la programación",
        start_later_than_end: "Hora de inicio posterior a hora de fin",
        overlap_in_start_time: "Solapamiento en hora de inicio para el {weekday}",
        overlap_in_end_time: "Solapamiento en hora de fin para el {weekday}",
        no_tasks: "No hay tareas dadas de alta en el sistema",
        task_del_confirmation: "¿Desea eliminar la programación '{taskname}'?",
    },

    users: {
        current_password: "Contraseña actual",
        new_password: "Nueva contraseña",
        password_incorrect: "La contraseña actual no es correcta",
        password_too_short: "La contraseña debe tener al menos 8 caracteres",
        error_updating_password: "Error al actualizar la contraseña",
        privileges: "Privilegios",
        first_name: "Nombre",
        user_first_name: "Nombre del usuario",
        surname: "Apellidos",
        user_surname: "Apellidos del usuario",
        email: "E-mail",
        user_email: "E-mail del usuario",
        password: "Contraseña",
        user_password: "Contraseña del usuario",
        first_name_required: "Nombre de usuario requerido",
        surname_required: "Apellidos de usuario requeridos",
        email_required: "E-mail de usuario requerido",
        email_format_invalid: "El formato del e-mail no es válido",
        error_saving_user: "Error al guardar el usuario",
        error_updating_user: "Error al actualizar el usuario",
        no_users: "No hay usuarios dados de alta en el sistema",
        user_del_confirmation: "¿Desea eliminar el usuario '{useremail}'?",
    },

    privileges: {
        view_cameras: "Ver cámaras",
        ptz: "PTZ",
        ondemand_recording: "Grabación bajo demanda",
        view_recordings: "Ver grabaciones",
        export_recordings: "Exportar grabaciones",
        delete_recordings: "Eliminar grabaciones",
        edit_cameras: "Modificar cámaras",
        edit_tasks: "Modificar programaciones",
        edit_groups: "Modificar grupos",
    },

    languages: {
        es: "Español",
        en: "English",
    },

    settings: {
        language: "Idioma",
        notifications: "Notificaciones",
        error_updating_language: "Error al actualizar el idioma",
        motion_detection: "Detección de movimiento",
        audio_detection: "Detección de audio",
        connectivity_events: "Eventos de conectividad",
        label_configuration: "Configuración de etiquetas",
        external_events: "Eventos externos",

    }
};

export default es;
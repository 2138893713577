import "bootstrap/dist/css/bootstrap.min.css"
import './assets/custom/css/custom.css';
import './assets/plugins/Font-awesome/font-awesome-4.7.0/css/font-awesome.min.css';
import './assets/plugins/googleapis.css';
import './assets/custom/fonts/materialicons.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from "./routers";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import MenuMain from "./components/Menus/MenuMain.vue";
import MenuFooter from "./components/Menus/MenuFooter.vue";
import HeaderMain from "./components/HeaderMain.vue";
import i18n from './locales/i18n-config'
import Notifications from '@kyvg/vue3-notification'


// 1. Assign app to a variable
let app = createApp(App);

//Components Global Registration
app.component('MenuMain', MenuMain)
app.component('MenuFooter', MenuFooter)
app.component('HeaderMain', HeaderMain)

// 2. Assign the global variables before mounting
app.config.globalProperties.APIVersion="restapi2";
//app.config.globalProperties.APIServer="https://ipronet.ipnvsaas.net";
app.config.globalProperties.APIServer="https://vipdemo.ipnvsaas.net";
app.config.globalProperties.APIUrl = app.config.globalProperties.APIServer + "/"  + app.config.globalProperties.APIVersion;
app.config.globalProperties.MQTTUser = "ipronet";
app.config.globalProperties.MQTTPassword = "Ipo809x!";

app.config.globalProperties.S_SESSIONERROR = -2;
//global functions.
app.mixin({
    methods: {
      logOut: function () {
        //sessionStorage.removeItem("userInfo");
        //sessionStorage.removeItem("userLogin");
        //sessionStorage.removeItem("default_user_privileges");
        //sessionStorage.removeItem("parameters");
        this.$root.sessionManager.logout();
        this.$router.push({ name: "SignIn" });
        this.$root.dataManager.clear();
      },
    },
  })


// 3. Use router and mount app
app.use(router)
app.use(VueSweetalert2);
app.use(i18n);
app.use(Notifications);
app.mount('#app');
if (process.env.NODE_ENV!=="development")
{
  console.log=function(){};  
}

//let dataManager=new DataManager();

//import "jquery/src/jquery.js"
import "bootstrap/dist/js/bootstrap.bundle.js";

/*import "js/ipnplayer/ipnplayer.js";*/
/*
Dropdowns are built on a third party library, Popper, which provides dynamic positioning and viewport detection.
Be sure to include popper.min.js before Bootstrap’s JavaScript or use bootstrap.bundle.min.js / bootstrap.bundle.js which contains Popper.
Popper isn’t used to position dropdowns in navbars though as dynamic positioning isn’t required.
*/



import HomeMain from "./components/HomeMain";
import SignIn from "./components/Login/SignIn";
import RememberMe  from "./components/Login/RememberMe"
import PasswordUpdate from "./components/Login/PasswordUpdate"
import UsersMain  from "./components/Users/UsersMain"
import UserAdd  from "./components/Users/UserAdd"
import UserEdit  from "./components/Users/UserEdit"
import PasswordChange from "./components/Users/PasswordChange"
import RecordingsMain  from "./components/Recordings/RecordingsMain"
import CamerasMain  from "./components/Cameras/CamerasMain"
import CameraAdd from  "./components/Cameras/CameraAdd"
import CameraWifi from  "./components/Cameras/CameraWifi"
import CameraEdit from  "./components/Cameras/CameraEdit"
import GroupsMain  from "./components/Groups/GroupsMain"
import GroupAdd from  "./components/Groups/GroupAdd"
import GroupEdit from  "./components/Groups/GroupEdit"
import TasksMain  from "./components/Tasks/TasksMain"
import TaskAdd from  "./components/Tasks/TaskAdd"
import TaskEdit from  "./components/Tasks/TaskEdit"
import ExportAdd from "./components/Exports/ExportAdd"
import ExportsMain from "./components/Exports/ExportsMain"
import LabelsMain  from "./components/Labels/LabelsMain"
import LabelAdd  from "./components/Labels/LabelAdd"
import LabelEdit  from "./components/Labels/LabelEdit"
import NotificationsMain  from "./components/Notifications/NotificationsMain"
import Settings  from "./components/Users/Settings"


import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        name: "HomeMain",
        component: HomeMain,
        path: "/"
    },
    {
        name: "SignIn",
        component: SignIn,
        path: "/login"
    },
    {
        name: "CamerasMain",
        component: CamerasMain,
        path: "/cameras"
    },
    {
        name: "CameraAdd",
        component: CameraAdd,
        path: "/cameras/new"
    },
    {
        name: "CameraEdit",
        component: CameraEdit,
        path: "/cameras/edit/:editId",
        props:true
    },
    {
        name: "CameraWifi",
        component: CameraWifi,
        path: "/cameras/wifi/:editId",
        props:true
    },
    {
        name: "TaskAdd",
        component: TaskAdd,
        path: "/tasks/new"
    },
    {
        name: "TaskEdit",
        component: TaskEdit,
        path: "/tasks/edit/:editId",
        props: true,
    },
    {
        name: "GroupsMain",
        component: GroupsMain,
        path: "/groups"
    },
    {
        name: "GroupAdd",
        component: GroupAdd,
        path: "/groups/new"
    },
    {
        name: "GroupEdit",
        component: GroupEdit,
        path: "/groups/edit/:editId",
        props: true
    },
    {
        name: "RecordingsMain",
        component: RecordingsMain,
        path: "/recordings"
    },
    {
        name: "ExportsMain",
        component: ExportsMain,
        path: "/exports"
    },
    {
        name: "TasksMain",
        component: TasksMain,
        path: "/tasks"
    },
    {
        name: "ExportAdd",
        component: ExportAdd,
        path: "/recordings/export/:paramRecId",
        props:true
    },
    {
        name: "UsersMain",
        component: UsersMain,
        path: "/users"
    },
    {
        name: "UserAdd",
        component: UserAdd,
        path: "/users/new"
    },
    {
        name: "UserEdit",
        component: UserEdit,
        path: "/users/edit/:editId",
        props: true
    },
    {
        name: "RememberMe",
        component: RememberMe,
        path: "/rememberme"
    },
    {
        name: "PasswordChange",
        component: PasswordChange,
        path: "/password"
    },
    {
        name: "Settings",
        component: Settings,
        path: "/settings"
    },
    {
        name: "PasswordUpdate",
        component: PasswordUpdate,
        path: "/updatepassword"
    },
    {
        name: "LabelsMain",
        component: LabelsMain,
        path: "/labels"
    },
    {
        name: "LabelAdd",
        component: LabelAdd,
        path: "/labels/new"
    },
    {
        name: "LabelEdit",
        component: LabelEdit,
        path: "/labels/edit/:editId",
        props: true
    },
    {
        name: "NotificationsMain",
        component: NotificationsMain,
        path: "/notifications"
    }

];

const router = createRouter({
    scrollBehavior(/*to, from, savedPosition*/) {
        return { top: 0};
    },
    history: createWebHistory(process.env.BASE_URL),
    routes,

});
// router.beforeEach((to, from) => {

//     console.log("from",from);
//     console.log("to",to);
//     return true
//   });


export default router;
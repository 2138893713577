const en = {
    common: {
        save: "Save",
        delete: "Delete",
        update_preview: "Update preview",
        edit: "Edit",
        ok: "OK",
        cancel: "Cancel",
        name: "Name",
        date: "Date",
        start: "Start",
        end: "End",
        no_privileges: "User without privileges for this functionality",
        time: "Time",
        session_expired: "Session expired",
        cameras: "Cameras",
        configuration: "Configuration",
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds",
        groups: "Groups",
        information: "Information",
        yes: "Yes",
        no: "No",
        copyright: "Copyright © {year}",
        all_rights_reserved: "All rights reserved",
        recordings: "Recordings",
        tasks: "Tasks",
        labels: "Labels",
        exports: "Exports",
        notifications: "Notifications",
        search: "Search",
        filters: "Filters",
        users: "Users",
    },

    weekdays: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        all: "All",



    },
    cameras: {
        new_camera: "New camera",
        information: "Information",
        connection: "Connection",
        camera_name: "Name of the camera",
        mac: "MAC address",
        camera_mac: "MAC of the camera",
        vcode: "VCode",
        camera_vcode: "VCode of the camera",
        user: "User",
        camera_user: "User of the camera",
        password: "Password",
        camera_password: "Password of the camera",
        profile: "Profile",
        name_required: "Name is required",
        mac_required: "MAC is required",
        vcode_required: "VCode is required",
        user_required: "User is required",
        password_required: "Password is required",
        review_connection_params: "Review the connection parameters",
        motion: "Motion",
        video: "Video",
        audio: "Audio",
        channel: "Channel",
        brand: "Brand",
        camera_brand: "Manufacturer of the camera",
        model: "Model",
        camera_model: "Model of the camera",
        ip_dn: "IP/DN",
        camera_ip_dn: "IP/DN of the camera",
        camera_profile: "Profile of the camera",
        detection: "Detection",
        sensitivity: "Sensitivity",
        low: "Low",
        medium: "Medium",
        high: "High",
        region: "Region",
        full: "Full",
        custom: "Custom",
        enable: "Enable",
        error_updating_camera: "Error updating the camera",
        edit_camera: "Edit camera",
        setup_wifi: "Setup Wi-Fi",
        updating_preview: "Updating preview",
        no_preview_available: "No preview available",
        select_update_preview: "Select Update preview",
        error_updating_preview: "Error updating preview",
        no_cameras: "No cameras available in the system",
        no_cameras_with_label: "No cameras with selected label",
        cam_del_confirmation: "Do you wish to delete the camera '{camname}'?",
        no_licenses_for_new_camera: "You don't have enough licenses to add a new camera",
        cameras: "Cameras",
        security: "Security",
        searching_wifi: "Searching for Wi-Fi networks...",
        configuring_the_camera_wait: "Configuring the cámara. Please wait and do not leave the page...",
        error_getting_wifi: "Error getting the Wi-Fi parameters of the camera",
        error_getting_network_list: "Error getting the list of available networks",
        new_password_sent: "New password sent",
        please_wait: "Please wait...",
        configuring_wifi: "Configuring the Wi-Fi network of the camera",
        camera_configured_successfully: "Camera successfully configured",
        error_configuring_wifi: "Error configuring the Wi-Fi network",
        wifi_config: "Wi-Fi configuration",
        wifi_password: "Wi-Fi password",
        enter_password: "Enter a password",
        passwords_dont_match: "Passwords do not match",
        repeat_password: "Repeat password",

    },
    exports: {
        export_name: "Name of the export",
        export: "Export",
        export_name_required: "Export name is required",
        invalid_start_time: "Invalid start time",
        invalid_end_time: "Invalid end time",
        review_export_params: "Review the export parameters",
        download: "Download",
        no_exports: "No exports available in the system",
        export_del_confirmation: "Do you wish to delete the export of camera '{camname}'?",
    },

    groups: {
        interval: "Interval",
        group_name: "Name of the group",
        geometry: "Geometry",
        name_required: "Name of the group is required",
        at_least_one_camera: "You should select at least one camera",
        error_saving_group: "Error saving the group",
        error_updating_group: "Error updating the group",
        new_group: "New group",
        edit_group: "Edit group",
        updating_preview: "Updating preview",
        no_preview_available: "No preview available",
        select_update_preview: "Select Update preview",
        error_updating_preview: "Error updating preview",
        no_groups: "No groups available in the system",
        no_groups_with_label: "No groups with selected label",
        group_del_confirmation: "Do you wish to delete the group '{groupname}'?",
    },

    labels: {
        label_name: "Name of the label",
        name_required: "Name of the label is required",
        error_saving_label: "Error saving the label",
        error_updating_label: "Error updating the label",
        no_labels: "No labels defined",
        alerts_enabled: "Alerts enabled",
        label_del_confirmation: "Do you wish to delete the label '{labelname}'?",
    },

    login: {
        email: "E-mail",
        new_password: "New password",
        invalid_email: "Invalid e-mail",
        password_too_short: "The password must have at least 8 characters",
        password_updated: "Password updated",
        error_updating_password: "Error updating password",
        send: "SEND",
        review_inbox: "Check your e-mail inbox",
        error_sending_email: "Error sending e-mail",
        password: "Password",
        invalid_credentials: "Invalid credentials",
        forgot_password: "Did you forget your password?",
        enter: "ENTER",
        entering_our_web: "By entering this website you accept our",
        terms_of_service: "terms of service",
        welcome: "Welcome",


    },

    menus: {
        close_session: "Close session",
        my_account: "My account",
        users: "Users",
        change_password: "Change password",
        settings: "Settings",
        user_manual: "User's manual",
        all: "All",


    }, 

    notifications: {
        no_notifications: "No notifications",
        type: "Type",
        info: "Info",
        mark_all_read: "Mark all as read",
        delete_all: "Delete all",
        notifications_enabled: "Notifications enabled",
        notifications_disabled: "Notifications disabled",
        connection_lost: "Connection lost!",
        connection_recovered: "Connection recovered!",
        motion_detected: "Motion detected!",
        audio_detected: "Audio detected!",
        live_popup: "Event received!",
        unknown: "Unknown",

    },

    recordings: {
        export: "Export",
        lock: "Lock",
        unlock: "Unlock",
        type: "Type",
        camera: "Camera",
        select: "Select",
        no_recordings: "No recordings available in the system",
        rec_del_confirmation: "Do you wish to delete the recording of the camera '{camname}'?",
        no_recs_for_date: "No recordings available for the specified date",
        
    },

    tasks: {
        timetable: "Timetable",
        task_name: "Name of the task",
        type: "Type",
        continuous: "Continuous recording",
        motion_detection: "Motion detection",
        motion_notification: "Motion notification",
        audio_notification: "Audio notification",
        day: "Day",
        add: "Add",
        unassigned: "Unassigned",
        all: "All",
        name_required: "Name of the task is required",
        at_least_one_timetable: "You should select at least one timetable",
        at_least_one_camera: "You should select at least one camera",
        error_saving_task: "Error saving the task",
        error_updating_task: "Error updating the task",
        start_later_than_end: "Start time is later than end time",
        overlap_in_start_time: "Overlap in start time for {weekday}",
        overlap_in_end_time: "Overlap in end time for {weekday}",
        no_tasks: "No tasks configured in the system",
        task_del_confirmation: "Do you wish to delete the task '{taskname}'?",


    },

    users: {
        current_password: "Current password",
        new_password: "New password",
        password_incorrect: "Current password is incorrect",
        password_too_short: "The password must have at least 8 characters",
        error_updating_password: "Error updating the password",
        privileges: "Privileges",
        first_name: "First name",
        user_first_name: "User's first name",
        surname: "Surname",
        user_surname: "User's surname",
        email: "E-mail",
        user_email: "User's e-mail",
        password: "Password",
        user_password: "User's password",
        first_name_required: "First name is required",
        surname_required: "Surname is required",
        email_required: "E-mail is required",
        email_format_invalid: "Invalid e-mail format",
        error_saving_user: "Error saving the user",
        error_updating_user: "Error updating the user",
        no_users: "No users configured in the system",
        user_del_confirmation: "Do you wish to delete the user '{useremail}'?",
    },

    privileges: {
        view_cameras: "View cameras",
        ptz: "PTZ",
        ondemand_recording: "On-demand recording",
        view_recordings: "View recordings",
        export_recordings: "Export recordings",
        delete_recordings: "Delete recordings",
        edit_cameras: "Edit cameras",
        edit_tasks: "Edit tasks",
        edit_groups: "Edit groups",
        

    },

    languages: {
        es: "Español",
        en: "English",
        
    },

    settings: {
        language: "Language",
        notifications: "Notifications",
        error_updating_language: "Error updating the language",
        motion_detection: "Motion detection",
        audio_detection: "Audio detection",
        connectivity_events: "Connectivity events",
        label_configuration: "Label configuration",
        external_events: "External events",
    }

};

export default en;
<template>
  <menu-main></menu-main>
  <div id="cameraAdd">
    <header-main> </header-main>
    <div class="cuerpo_principal">
      <div v-show="addCamerasPriv" id="confProg" class="container">
        <div class="row">
          <div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 visor_box">
            <div class="alert alert-custom" id="msg-alert">
              <p>{{ errorMessage }}</p>
            </div>
            <ul class="nav nav-tabs nav-fill" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: isInformationActive }"
                  id="informacion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#informacion"
                  type="button"
                  role="tab"
                  aria-controls="informacion"
                  aria-selected="true"
                >
                {{ $t('cameras.information') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: isConnectionActive }"
                  id="connection-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#connection"
                  type="button"
                  role="tab"
                  aria-controls="connection"
                  aria-selected="false"
                >
                {{ $t('cameras.connection') }}
                </button>
              </li>
              
            </ul>
						<button
              @click="closeWindow"
              type="button"
              class="close_props material-icons"
              style="float: right"
              aria-label="Close"
            >close</button>
            <div class="tab-content" id="npContenido">
              <!--TAB1-->
              <div
                class="tab-pane fade show active"
                id="informacion"
                role="tabpanel"
                aria-labelledby="informacion-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('common.name') }}</p>
                    <input class="text_input"
                      v-model="name"
                      id="camera_name"
                      type="text"
                      :placeholder="$t('cameras.camera_name')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.mac') }}</p>
                    <input class="text_input"
                      v-model="mac"
                      id="camera_mac"
                      type="text"
                      :placeholder="$t('cameras.camera_mac')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.vcode') }}</p>
                    <input class="text_input"
                      v-model="vcode"
                      id="camera_vcode"
                      type="text"
                      :placeholder="$t('cameras.camera_vcode')"
                    />
                  </div>
                </div>
                <!--end conf_box-->
              </div>
              <!--TAB2-->
              <div
                class="tab-pane fade"
                id="connection"
                role="tabpanel"
                aria-labelledby="connection-tab"
              >
                <div class="conf_box">
                  <div class="nom_input">
                    <p>{{ $t('cameras.user') }}</p>
                    <input class="text_input"
                      v-model="username"
                      id="camera_user"
                      type="text"
                      :placeholder="$t('cameras.camera_user')"
                    />
                  </div>
                  <div class="nom_input">
                    <p>{{ $t('cameras.password') }}</p>
                    <input class="text_input"
                      v-model="password"
                      id="camera_password"
                      type="password"
                      :placeholder="$t('cameras.camera_password')"
                    />
                  </div>
                  <div class="p_tipo">
                    <p>{{ $t('cameras.profile') }}</p>
                    <div class="conf_tipo">
                      <select class="dropdown_select"
                        v-model="profileSelected"
                        id="camera_profile"
                        name="profile"
                      >
                        <option
                          v-for="profile in profiles"
                          :key="profile.i_value"
                          :value="profile.value"
                        >
                          {{ profile.text }}
                        </option>
                        <!--<option value="lightplus">Lite+</option>
                        <option value="pro">Pro</option>
                        <option value="premium">Premium</option>-->
                      </select>
                    </div>
                  </div>
                </div>
                <!--end conf_box-->
              </div>
            </div>
            <!--end npContenido-->
            <div
              :class="
                saveButtonDisabled ? 'disabled_btn_guardar' : 'btn_guardar'
              "
            >
              <button :disabled="saveButtonDisabled" @click="addCamera">
                <a href="#">{{ $t('common.save') }}</a>
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <menu-footer active-state="cameras"></menu-footer>
</template>

<script>
import $ from "jquery";

//const axios = require("axios").default;
//axios.defaults.withCredentials = false;

export default {
  name: "CameraAdd",
  data() {
    return {
      sessionId: "",
      name: "",
      mac: "",
      vcode: "",
      username: "",
      password: "",
      profileSelected: "",
      profiles: [],
      errorMessage: "",
      //error: false,
      //loaded: false,
      saving: false,
      addCamerasPriv: false,
      isInformationActive: true,
      isConnectionActive: false,
    };
  },
  computed: {
    saveButtonDisabled() {
      if (this.name == "") {
        return true;
      }
      if (this.mac == "") {
        return true;
      }
      if (this.vcode == "") {
        return true;
      }
      if (this.username == "") {
        return true;
      }
      if (this.password == "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    showMsgAlert() {
      $("#msg-alert")
        .fadeTo(200, .80, function() {
					setTimeout(function() {
						$("#msg-alert").fadeTo(200,0);
						},2000)
				});
    },
    closeWindow() {
      console.log("closeWindow");
      //this.$router.push({ name: "CamerasMain" });
      this.$router.go(-1);
    },
    verifyData() {
      if (this.name == "") {
        this.errorMessage = this.$t('common.name_required');
        //this.error = true;
        this.isInformationActive = true;
        this.isConnectionActive = false;
        document.getElementById("camera_name").focus();
        return false;
      }
      if (this.mac == "") {
        this.errorMessage = this.$t('cameras.mac_required');
        //this.error = true;
        this.isInformationActive = true;
        this.isConnectionActive = false;
        document.getElementById("camera_mac").focus();
        return false;
      }
      if (this.vcode == "") {
        this.errorMessage = this.$t('cameras.vcode_required');
        //this.error = true;
        this.isInformationActive = true;
        this.isConnectionActive = false;
        document.getElementById("camera_vcode").focus();
        return false;
      }
      if (this.username == "") {
        this.errorMessage = this.$t('cameras.user_required');
        //this.error = true;
        this.isInformationActive = false;
        this.isConnectionActive = true;
        document.getElementById("camera_user").focus();
        return false;
      }
      if (this.password == "") {
        this.errorMessage = this.$t('cameras.password_required');
        //this.error = true;
        this.isInformationActive = false;
        this.isConnectionActive = true;
        document.getElementById("camera_password").focus();
        return false;
      }
      return true;
    },
    addCamera() {
      //this.error = false;
      this.errorMessage = "";
      console.log("addCamera");
      if (this.verifyData()) {
        console.log("verify data ok");
        this.saving = true;
        this.addCameraIPNP();
      } else {
        this.showMsgAlert();
      }
    },

    async addCameraIPNP() {
      try {
        const response=await this.$root.dataManager.addCamera(this.name,this.mac,this.vcode,this.username,this.password,this.profileSelected);
        console.log(response);
        await this.$root.dataManager.updateCameraPreview(response.camId);
        this.saving = false;
        this.$router.push({ name: "CamerasMain" });
      } catch (error) {
        console.log("addCameraIPNP error");
        console.error(error);
        this.saving = false;
        //this.error = true;
        this.errorMessage = this.$t('cameras.review_connection_params');
        this.showMsgAlert();
      }
    },



    // async createPreview(id) {
    //   const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //   console.log("createPreview:", userInfo.sessionId);
    //   const url =
    //     userInfo.APIUrl + "/cameras/" + id + "/preview/?q=" + userInfo.sessionId;
    //   console.log(url);
    //   try {
    //     const response = await axios.put(url);
    //     if (response.status == 200) {
    //       console.log("createPreview OK");
    //       this.error = false;
    //     }
    //   } catch (error) {
    //     console.log("error creating preview", error);
    //     this.error = true;
    //   }
    //   this.loading = false;
    //   this.$router.push({ name: "CamerasMain" });
    // },

    // async addCameraIPNP() {
    //   const data = {
    //     camname: this.name,
    //     mac: this.mac,
    //     vcode: this.vcode,
    //     username: this.username,
    //     password: this.password,
    //     profile: this.profileSelected,
    //   };
    //   const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //   console.log("addCameraIPNP:", userInfo.sessionId);
    //   console.log("addCameraIPNP data:", data);
    //   const url = userInfo.APIUrl + "/cameras/?q=" + userInfo.sessionId;
    //   try {
    //     const response = await axios.post(url, data);
    //     console.log(response.status);
    //     console.log(response.data);
    //     if (response.status == 200 && response.data.result == 0) {
    //       console.log("addCameraIPNP OK");
    //       this.createPreview(response.data.camId);
    //     } else {
    //       console.log("addCameraIPNP error");
    //       this.error = true;
    //       this.loading = false;
    //       this.errorMessage = "Revise parámetros de conexión";
    //       this.showMsgAlert();
    //     }
    //   } catch (error) {
    //     console.log("addCameraIPNP error");
    //     console.error(error);
    //     this.loading = false;
    //     this.error = true;
    //     this.errorMessage = "Revise parámetros de conexión";
    //   }
    // },
    addElementsToProfileSelect() {
      //let licenses = JSON.parse(sessionStorage.getItem("userLicenses"));
      let licenses=this.$root.dataManager.licenses;
      licenses.sort((x, y) => x.prioriy - y.prioriy);
      let priority = 4;
      let selected = "ligth";
      for (const license of licenses) {
        if (license.left > 0) {
          console.log("license", license);
          this.profiles.push({
            i_value: license.profileId,
            value: license.profileValue,
            text: license.profileText,
          });
          if (license.priority < priority) {
            priority = license.priority;
            selected = license.profileValue;
          }
        }
      }
      this.profileSelected = selected;
    },
  },

  mounted() {
    console.log("CameraAdd");
    document.getElementById("camera_name").focus();
    document.getElementById("camera_name").blur();
    document.title = this.$t('cameras.new_camera');
    $("#msg-alert").hide();
    this.isInformationActive = true;
    this.isConnectionActive = false;
    //const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!this.$root.sessionManager.isLoggedIn()) {
      console.log("router to signin");
      this.$router.push({ name: "SignIn" });
    }
    //const license = sessionStorage.getItem("userLicense");
    const license = this.$root.dataManager.totalLicensesLeft;
    if (license < 1) {
      console.log("router to CamerasMain not enought licenses");
      this.$router.push({ name: "CamerasMain" });
    }
    console.log("CameraAdd, sessionid:", this.$root.sessionManager.sessionId);
    this.sessionId = this.$root.sessionManager.sessionId;
    //const userPriv = JSON.parse(sessionStorage.getItem("userPriv"));
    const userPriv = this.$root.sessionManager.privileges;
    
    console.log("UsersMain, userPriv:", userPriv);
    this.addCamerasPriv = userPriv["addCameras"];
    this.addElementsToProfileSelect();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>